import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Divider from "@mui/material/Divider";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./LinkedinSearch.css";
import { useNavigate } from "react-router-dom";
import { setHeaderStepTwoCompleted } from "../Services/Actions/actionCreators";
import Loader from "../Common/Loader/Loader";
import UserImage from "../Assets/Images/user-palceholder.jpg"; // default image if api responce image gives error

interface LinkedinSearchProps {
  isTesting?: any
}
const LinkedinSearch: FC<LinkedinSearchProps> = ({ isTesting }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPersons = useSelector(
    (state: any) => state.selectedPersonData.selectedPersons
  );

  const [selectedPersonIndex, setSelectedPersonIndex] = useState(0);
  const [selectedPerson, setSelectedPerson] = useState(selectedPersons[0]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [records, setRecords] = React.useState([] as any[]);

  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  /* istanbul ignore next */
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText("#013365"),
    backgroundColor: "#013365",
    "&:hover": {
      backgroundColor: "#013365",
    },
    textTransform: "none",
  }));

  const navigateToPage = (path: any) => {
    navigate(path);
  };

  const handleClick = () => {
    dispatch(setHeaderStepTwoCompleted());
  };

  let tableData = () => {
    if (selectedPersons.length > 0) {
      let results: any = [
        {
          sNo: 1,
          data: "Occupation",
          value: selectedPersons[selectedPersonIndex]?.data?.occupation,
        },
        {
          sNo: 2,
          data: "Location",
          value: selectedPersons[selectedPersonIndex]?.data?.city,
        },
        {
          sNo: 3,
          data: "Followers",
          value: selectedPersons[selectedPersonIndex]?.data?.follower_count,
        },
        {
          sNo: 4,
          data: "Connections",
          value: selectedPersons[selectedPersonIndex]?.data?.connections,
        },
      ];
      setRecords(results);
    }
    setSelectedPerson(selectedPersons[selectedPersonIndex]);
  };

  /* istanbul ignore next */
  useEffect(() => {
    tableData();
  }, [selectedPersons, selectedPersonIndex]);

  /* istanbul ignore next */
  useEffect(() => {
    tableData();
  }, [selectedPersonIndex]);

  return loaderStore ? (
    <Loader />
  ) : (
    <Box className="custom-main-div">
      <Box className="table-box-header">
        <Grid container spacing={2} className="table-box">
          <Grid item xs={8} className="custm-table-box1">
            <Box className="tbl-grid-box-linkedinSearch">
              <Grid container spacing={1} className="bgc">
                <Grid item xs={12} sm={5}>
                  <div className="profile-pic-container">
                    <img
                      src={selectedPerson?.image}
                      alt="Profile-pic"
                      className="profile-pic"
                      onError={(
                        e: React.SyntheticEvent<HTMLImageElement, Event>
                      ) => {
                        e.currentTarget.src = UserImage; // Set the src to the default image
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={7}>
                  <Box className="profile-pic-table">
                    <Box>
                      <Typography variant="h5" className="custom-heading">
                        {selectedPerson?.data?.full_name}
                      </Typography>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table
                        aria-label="simple table"
                      >
                        <TableHead sx={{ bgcolor: "#E8EEF3" }}>
                          <TableRow>
                            <TableCell className="table-linkedin-srch">
                              S.No
                              <span className="custom-divider-table1"></span>
                            </TableCell>

                            <TableCell className="table-linkedin-srch">
                              Data
                              <span className="custom-divider-table1"></span>
                            </TableCell>

                            <TableCell className="table-linkedin-srch">
                              Values
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {records?.map((row) => (
                            <TableRow
                              key={row.sNo}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.sNo}
                              </TableCell>
                              <TableCell>{row.data}</TableCell>
                              <TableCell>{row.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Box>
  <Typography variant="h5" className="custom-heading">
    Summary
  </Typography>
  <Typography>
    {selectedPerson?.data?.summary || "No records"}
  </Typography>
</Box>
<Divider variant="middle" className="custom-divider" />

<Box>
  <Typography variant="h5" className="custom-heading">
    Skills
  </Typography>
  <Typography>
    {selectedPerson?.data?.skills?.length > 0
      ? selectedPerson.data.skills.join(", ")
      : "No records"}
  </Typography>
</Box>
<Divider variant="middle" className="custom-divider" />

<Box className="activities-box">
  <Typography variant="h5" className="custom-heading">
    Top 5 Activities:
  </Typography>
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead sx={{ bgcolor: "#E8EEF3" }}>
        <TableRow>
          <TableCell className="table-linkedin-srch">
            S.No
            <span className="custom-divider-table2"></span>
          </TableCell>
          <TableCell className="table-linkedin-srch">
            Title
            <span className="custom-divider-table3"></span>
          </TableCell>
          <TableCell className="table-linkedin-srch">
            Post Link
            <span className="custom-divider-table4"></span>
          </TableCell>
          <TableCell className="table-linkedin-srch">Activity Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedPerson?.data?.activities?.length > 0 ? (
          selectedPerson.data.activities.map(
            (activity: any, index: any) => (
              <TableRow
                key={activity.title}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{activity?.title}</TableCell>
                <TableCell>
                  <Link target="_blank" href={activity.link}>
                    {activity.link}
                  </Link>
                </TableCell>
                <TableCell>{activity.activity_status}</TableCell>
              </TableRow>
            )
          )
        ) : (
          <TableRow>
            <TableCell colSpan={4} align="center">
              No records
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
</Box>
<Divider variant="middle" className="custom-divider" />

<Box className="work-exp-box">
  <Typography variant="h5" className="custom-heading">
    Professional Work Experience
  </Typography>
  <Box className="custom-accordion-div">
    {selectedPerson?.data?.experiences?.length > 0 ? (
      selectedPerson.data.experiences.map((experience: any, index: any) => (
        <Accordion
          key={experience.company}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className="accordion-box"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#343434" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>
              {experience?.starts_at?.year}-{experience?.company}-
              {experience?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#FFFFFF",
              fontSize: "16px",
              boxShadow: 0,
            }}
          >
            <Typography variant="body2">
              <span className="heading">Company</span>: {experience?.company}{" "}
              <br />
            </Typography>
            <Typography variant="body2">
              <span className="heading">Title</span>: {experience?.title}
              <br />
            </Typography>
            <Typography variant="body2">
              <span className="heading">Location</span>: {experience?.location}
              <br />
            </Typography>
            <Typography variant="body2">
              <span className="heading">Duration</span>:
              {experience?.starts_at?.day}
              {"/"}
              {experience?.starts_at?.month}
              {"/"}
              {experience?.starts_at?.year || ""} {" - "}
              {experience?.ends_at?.day || ""}
              {experience?.ends_at?.day ? "/" : ""}
              {experience?.ends_at?.month}
              {experience?.ends_at?.month ? "/" : ""}
              {experience?.ends_at?.year || ""}
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))
    ) : (
      <Typography align="center" variant="body1">
        No records
      </Typography>
    )}
  </Box>
</Box>

              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="main-div-radio">
              <Box className="box-hdr-txt">
                <Typography variant="h5" className="custom-heading">
                  Profiles
                </Typography>

                <ColorButton
                  variant="contained"
                  onClick={() => {
                    handleClick();
                    navigateToPage("/generate-mail");
                  }}
                >
                  Generate Email for All
                </ColorButton>
              </Box>
              <Divider className="custom-divider" />

              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedPersonIndex.toString()} // Convert index to string for value matching
                    onChange={(event) =>
                      setSelectedPersonIndex(Number(event.target.value))
                    }
                  >
                    {selectedPersons?.map((person: any, index: any) => (
                      <FormControlLabel
                        key={person.data?.full_name}
                        value={index.toString()}
                        control={<Radio />}
                        label={person.data?.full_name}
                        data-testid={person?.full_name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default LinkedinSearch;
