import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Breadcrumb = ({ title, id }) => {
  return (
    <Box 
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <nav aria-label="breadcrumb">
        <ol
          style={{
            listStyle: "none",
            display: "flex",
            padding: 0,
            marginBottom: "10px",
          }}
        >
          <li>
            <a
              href="#"
              style={{ textDecoration: "none", color: "#007bff" }}
              onClick={() => window.history.back()}
            >
              {title}
            </a>
          </li>
          <li style={{ marginLeft: "5px", color: "#6c757d" }}> {">"} {id}</li>
        </ol>
      </nav>
    </Box>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired, // Title for the link
  id: PropTypes.string,    // Dynamic identifier
};

export default Breadcrumb;
