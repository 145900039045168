import React from "react";
import { Box, Button, Divider, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import moment from "moment";
import { toast } from "react-toastify";

import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./persondata.css";
import { useNavigate } from "react-router-dom";
import { getProfile, setSelectedPersons } from "../Services/Actions/actions";
import { useDispatch } from "react-redux";
import {
  setHeaderStepOneCompleted,
  setLoader,
  updateGetProfileData,
  updateSalesforceData,
} from "../Services/Actions/actionCreators";
import Loader from "./Loader/Loader";
import { styled } from "@mui/system";

interface Props {
  selectedRowCount: any;
  contacts: any;
  loadUserDetail: any;
  selectedRadioValue: any;
  selectedSalesforceData: any;
}

const StyledButton = styled(Button)({
  textTransform: "none", // This removes the uppercase transformation
});

export const PersonData: React.FC<Props> = ({
  selectedRowCount,
  contacts,
  loadUserDetail,
  selectedRadioValue,
  selectedSalesforceData,
}) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState<string | false>(`panel0`);

  const dispatch = useDispatch();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  /* istanbul ignore next */
  const handleClick = async (contacts: any) => {
    // Navigate first
    navigate("/linkedin-search");
  
    try {
      dispatch(setLoader(true));
  
      let res;
      if (selectedRadioValue === "hubspot") {
        res = await getProfile(contacts);
        dispatch(setSelectedPersons(res?.data?.valid_profiles));
      } else {
        res = await getProfile(selectedSalesforceData);
        dispatch(updateGetProfileData(res));
        dispatch(setSelectedPersons(res?.data?.valid_profiles));
        dispatch(updateSalesforceData([...res?.data?.valid_profiles]));
      }
      
      dispatch(setHeaderStepOneCompleted());
  
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Error fetching data. Please try again.");
    } finally {
      dispatch(setLoader(false));
    }
  };

  const renderAccordionContent = () => {
    if (loadUserDetail) {
      return <Loader />;
    }

    let accordionItems;
    if (selectedRadioValue === "hubspot") {
      accordionItems = renderAccordionItems(contacts, true);
    } else {
      accordionItems = renderAccordionItems(selectedSalesforceData, false);
    }

    return <Box className="custom-sec-box-mini">{accordionItems}</Box>;
  };

  const renderAccordionItems = (data: any, isHubspot: any) => {
       return data?.map((item: any, index: any) => {
        const expandedValue = expanded === `panel${index}`;
        const handleChangeValue = handleChange(`panel${index}`);
        const fullName = `${item?.data?.properties?.firstname} ${item?.data?.properties?.lastname}`;
  
        return (
          <Accordion
            key={`item.id`}
            expanded={expandedValue}
            onChange={handleChangeValue}
            className="accordion-box"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                {fullName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              className="custom-sec-box"
              sx={{
                height: isHubspot ? 220 : 300,
                width: "100%",
                bgcolor: "#FFFFFF",
                fontSize: "16px",
                boxShadow: 0,
              }}
            >
              <Typography variant="body2">
                <span className="heading">Full Name</span>: {fullName}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">Job Title</b>:{" "}
                {item.data.properties.jobtitle}
                <br />
              </Typography>
  
              <Typography variant="body2">
                <b className="heading">Company Name</b>:{" "}
                {item.data.properties.company ?? item.data.properties.account_name}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">Email</b>: {item.data.properties.email}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">Phone Number</b>:{" "}
                {item?.data?.properties?.phone?.includes("'")
                  ? item?.data?.properties?.phone.slice(
                    1,
                    item?.data?.properties?.phone.length
                  )
                  : item?.data?.properties?.phone}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">LinkedIn Profile URL</b>:{" "}
                <Link target="_blank" href={item.data.properties.linkedin_contact_profile_url}>
                  {item.data.properties.linkedin_contact_profile_url}
                </Link>
                <br />{" "}
              </Typography>
              <Typography variant="body2">
                <b className="heading">City</b>: {item.data.properties.city}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">State/Region</b>:{" "}
                {item.data.properties.state}
                <br />
              </Typography>
              <Typography variant="body2">
                <b className="heading">Last Modified Date</b>:{" "}
               { !item?.data?.properties?.lastmodifieddate ? '-' :
              moment(item?.data?.properties?.lastmodifieddate).format(
                  "MM/DD/YYYY"
                )}
                <br />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      });
    
   
  };

  /* istanbul ignore next */
  return (
    <Box className="main-div">
      <Box className="box-hdr-txt">
        <Typography
          variant="h5"
          fontWeight={700}
          lineHeight={"26px"}
          ml={"32px"}
          mb={"8px"}
        >
          Summary
        </Typography>
      </Box>
      <Box>
        <Typography className="font-gld">
          Selected Persons: {selectedRowCount}
        </Typography>
      </Box>
      <Divider />
      <Box className="btn-box">
        <Typography className="font-gld">{selectedRowCount > 1
      ? "Get LinkedIn Data for all"
      : "Get LinkedIn Data"}</Typography>
        <StyledButton
          className="btn-gld"
          variant="contained"
          onClick={() => {
            handleClick(contacts);
          }}
          disabled={loadUserDetail}
        >
          Get LinkedIn data{" "}
        </StyledButton>
      </Box>
      <Divider />
      <Box className="btn-box">
        <Typography className="font-gld">View Person's Profile:</Typography>

        <Box className="accordion-container">{renderAccordionContent()}</Box>
      </Box>
    </Box>
  );
};
