import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
// import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import "./contactdetails.css";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useParams } from 'react-router-dom';
// import Button from "@mui/material/Button";
import searchScope from "../../Assets/Icons/searchScope.svg";

import Pagination from "../../Common/Pagination/Pagination";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader/Loader";
import { setLoader } from "../../Services/Actions/actionCreators";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Chip,
  Tooltip,
  IconButton,
  Button,
  TextField
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allContacts, contactDetails, retrieveNativecontactDetails } from "../../Services/Actions/actions";
import Breadcrumb from "../Breadcrumb";

interface Campaign {
  user_count: number;
  created_at: string;
  id: number;
  name: string;
  description: string;
  lastrandate: string;
  filename: string | null;
  status: string;
}

const ContactDetails: React.FC = () => {
  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { campaign } = location.state || {};
  const [salesforceListDataNeo, setSalesforceListDataNew] = React.useState({data: {
    data: [],
    total_records: 0,
  }});
console.log(salesforceListDataNeo, 'salesforceListDataNeo')
const { id } = useParams();
  console.log(campaign , "campaign")
  // Sample data for campaigns
  let [disbleBtn, setDisbleBtn] = React.useState<boolean>(true);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [value, setValue] = React.useState("");
  let pageSize = 5;
  const [totalContactsNumber, setTotalContactsNumber] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [campaigns, setCampaigns] = useState<Campaign[]>([
    {
      id: 1,
      name: "Marketing Campaign",
      description: "Targeted email marketing for Q4",
      filename: "marketing_campaign.csv",
      created_at: "2024-11-15T12:34:56Z",
      user_count: 250,
      lastrandate: "2024-11-20",
      status: "active",
    },
    {
      id: 2,
      name: "Holiday Promotion",
      description: "Discount campaign for the holiday season",
      filename: "holiday_promo.csv",
      created_at: "2024-11-10T10:20:30Z",
      user_count: 500,
      lastrandate: "2024-11-18",
      status: "inactive",
    },
  ]);
  const handleSearch = async () => {
    if (value) {
    
        Contactdetail(campaign.name)
    }
  }; 

  useEffect(()=>{
 
    dispatch(setLoader(true));
    Contactdetail(campaign.name)
  
      },[])

  const Contactdetail = async (campaignName: string) => {
    try {
      dispatch(setLoader(true));
      const res = await retrieveNativecontactDetails(
        campaignName, 
        value,    // Search value
        pageIndex,  // Current page index
        pageSize   // Page size
      );
      
      dispatch(setLoader(false));
      setSalesforceListDataNew((prevState) => ({
        ...prevState,
        data: res?.data,
        loading: false,
      }));
      setTotalContactsNumber(res?.data?.total_records);
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Failed to fetch contact details");
      console.error("Error fetching contact details:", error);
    }
  };
  
  const handleNext = (event: any, newPage: number) => {
    setPageIndex(newPage);
    setPage(newPage);
  };

  return (
<Box>
        <CssBaseline />
        <Container className="cust-frame-parent-UF">
          <Grid container spacing={2}>
            <Grid item xs={20}>
              <Box className="custom-breadcrumb" sx={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumb title="Campaign Management" id={id || "Unknown"} /> {/* Use Breadcrumb here */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1%",
                  }}
                >
                  <TextField
                    id="outlined-search"
                    size="small"
                    label="Search by keywords"
                    type="search"
                    sx={{
                      width: "380px",
                    }}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <IconButton
                    data-testid="search-btn"
                    onClick={handleSearch}
                    className="search-icon-unsubscribed"
                  >
                    <img
                      src={searchScope}
                      alt="search-icon"
                      className="img-srch-automated"
                    />
                  </IconButton>
                  {/* <Button
                    variant="contained"
                    size="medium"
                    className="save_changes usbtn-gld"
                    disabled={disbleBtn}
                    onClick={handleSaveupdateSwitch}
                    data-testid="save-btn"
                  >
                    Save Changes
                  </Button> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={20}  className="custom-width-1">
              {!loaderStore ? (
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    border: "1px solid #DBDBDB",
                    borderRadius: "0",
                  }}
                  className="custom-height"
                >
                  <TableContainer sx={{ maxHeight: 350 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow hover role="checkbox">
                       
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Name
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Job Title
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Email
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Linkdin Contact
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Phone 
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            State
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            Company
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            City
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody style={{ backgroundColor: "#F6F8FA" }}>

                   

                        {salesforceListDataNeo?.data?.data?.length >0 && salesforceListDataNeo?.data?.data?.map((item :any, index) => (
                          <TableRow key={item.id} hover role="checkbox">
                       <TableCell align="left">
                       {item.data.properties.firstname} {item.data.properties.lastname}
                      </TableCell>
                      <TableCell align="left">
                       {item.data.properties.jobtitle}
                      </TableCell>
                      
                           <TableCell component="td" id={item.id} scope="row">
                           <a href={`mailto:${item.data.properties.email}`} target="_blank" rel="noopener noreferrer">
                       {item.data.properties.email}
                       </a>
                      </TableCell>
                    
                      <TableCell align="left">
                      <a href={item.data.properties.linkedin_contact_profile_url} target="_blank" rel="noopener noreferrer">
                       {item.data.properties.linkedin_contact_profile_url}
                       </a>
                      </TableCell>
                      <TableCell align="left">
                       {item.data.properties.phone}
                      </TableCell>
                      <TableCell align="left">
                       {item.data.properties.state}
                      </TableCell>
                      <TableCell align="left">
                       {item.data.properties.company}
                      </TableCell>
                      <TableCell align="left">
                       {item.data.properties.city}
                      </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {pageSize < 2 ? (
                    ""
                  ) : (
                    <Box className="table-pagination">
                      <Pagination
                        count={totalContactsNumber}
                        pageSize={pageSize}
                        handleNext={handleNext}
                        page={page}
                      />
                    </Box>
                  )}
                </Paper>
              ) : (
                <Loader />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>  );
};

export default ContactDetails;
