import { postApiWithoutToken } from "./axios";

export const loginApi = (data) => {
  return postApiWithoutToken("/login", data);
};

export const registerApi = (data) => {
  return postApiWithoutToken("/register", data);
};

export const getValidToken = (data) => {
  return postApiWithoutToken("/token", data);
};
