import { FunctionComponent } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, InputAdornment, IconButton } from "@mui/material";
import "./Login.css";
import axios from "axios";

import GirlIcon from "../Assets/Icons/girlIcon.svg";
import DeskIcon from "../Assets/Icons/deskIcon.svg";
import MarketMinderLogo from "../Assets/Icons/MarketLogo.svg";
import compunnelLogo from "../Assets/Icons/compunnel-logo.svg";
import pcIcon from "../Assets/Icons/pcIcon.svg";
import { loginApi, registerApi } from "../API/endpointMethods";
import aiimage from "../Assets/Icons/aiimage.svg";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Registration
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    userName: ""
  });

  if (JSON.parse(localStorage.getItem("UserDetails") || "{}").token) {
    navigate("/dashboard");
    // window.location.reload();
  }

  const encodeString = (input: any) => {
    const encoder = new TextEncoder(); // Create a new TextEncoder instance
    const encoded = encoder.encode(input); // Encode the input string to UTF-8
    return encoded; // Returns a Uint8Array
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (formErrors[name as keyof typeof formErrors]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const proceedLogin = async (e: any) => {
    e.preventDefault();
    let payload = {
      "Username/Email": formData?.email,
      password: encodeString(formData?.password),
    };

    try {
      if (validate()) {
        const res: any = await loginApi(payload);
        if (res?.status === 200) {
          let userDetails = {
            token: res?.data?.token,
            email: formData?.email,
            firstName: res?.data?.first_name,
            lastName: res?.data?.last_name
          };
          localStorage.setItem("UserDetails", JSON.stringify(userDetails));
          setTimeout(() => {
            navigate("/dashboard");
          }, 100);
          toast.success(res.data.message)
        }else {
          toast.error(res.response.data.message)
        }
      }
    } catch (error) {}
  };

  const proceedRegister = async (e: any) => {
    e.preventDefault();
    let payload = {
      email: formData?.email,
      password: encodeString(formData?.password),
      first_name: formData?.firstName,
      last_name: formData?.lastName,
    };

    try {
      if (validate()) {
        const response: any = await registerApi(payload);
        if (response.status === 200) {
            // setIsLogin(true);
            let userDetails = {
              token: response?.data?.token,
              email: formData?.email,
              firstName: response?.data?.first_name,
            lastName: response?.data?.last_name
            };
            localStorage.setItem("UserDetails", JSON.stringify(userDetails));
          setTimeout(() => {
            navigate("/dashboard");
          }, 100);
            toast.success(response.data.message)
        } else {
          toast.error(response.response.data.message)
        }
      }
    } catch (error: any) {
      toast.error(error)
      // console.log('There was an error!', JSON.stringify(error.response.data));
    }
  }

  const validate = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    };

    if (!isLogin && !formData.firstName.trim()) {
      isValid = false;
      errors.firstName = "First Name is required";
    } else if (!isLogin && /\d/.test(formData.firstName)) {
      isValid = false;
      errors.firstName = "First Name cannot contain numbers";
    }
  
    // Last Name Validation
    if (!isLogin && !formData.lastName.trim()) {
      isValid = false;
      errors.lastName = "Last Name is required";
    } else if (!isLogin && /\d/.test(formData.lastName)) {
      isValid = false;
      errors.lastName = "Last Name cannot contain numbers";
    }

    if (
      !formData.email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ||
      !formData.email.endsWith(".com")
    ) {
      isValid = false;
      errors.email = "Valid email with @ and .com is required";
    }

  if (!formData.password) {
    isValid = false;
    errors.password = "Password is required";
  }

    if (!isLogin && formData.password !== formData.confirmPassword) {
    isValid = false;
    errors.confirmPassword = "Passwords do not match";
  }

    setFormErrors(errors);
    return isValid;
  };

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     try {
  //       const url = isLogin
  //         ? "http://your-backend-url/login"
  //         : "http://your-backend-url/register";

  //       const payload = isLogin
  //         ? { email: formData.email, password: formData.password }
  //         : {
  //             firstName: formData.firstName,
  //             lastName: formData.lastName,
  //             email: formData.email,
  //             password: formData.password,
  //           };

  //       const response = await axios.post(url, payload);

  //       if (response.status === 200 || response.status === 201) {
  //         console.log(isLogin ? "Login successful!" : "Registration successful!");
  //         if (isLogin) navigate("/dashboard");
  //         else setIsLogin(true); // Switch to Login after successful registration
  //       } else {
  //         console.log("Error:", response.data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };
  const handleUsernameChange = (e: any) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
    setUsername(e.target.value);
    if (formErrors.email) {
      setFormErrors({ ...formErrors, email: "" });
    }
  };

  const handlePasswordChange = (e: any) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
    setPassword(e.target.value);
    if (formErrors.password) {
      setFormErrors({ ...formErrors, password: "" });
    }
  };
  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleClickShowConfirmPassword = () => {
  //   setShowConfirmPassword(!showConfirmPassword);
  // };

  return (
    <Box className="log-in">
      <Box className="log-in-child"></Box>
      <Box className="frame-parent1">
        <Box className="frame-container-login">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className="logoContainer"
          >
            <img src={MarketMinderLogo} alt="" />
            <img src={compunnelLogo} alt="" />
          </Box>
          <Box className="get-started">
            <b>{`Market `}</b>
            <span className="minder">Minder AI</span>
          </Box>
        </Box>
        <Box className="group-group">
          <img className="loginImage" alt="" src={aiimage} />
        </Box>
      </Box>
      <Box className="frame-parent">
        <Box className="welcome-to-market-minder-parent">
          <Box className="welcome-to-market-container">
            <span>{`Welcome to `}</span>
            <b>Market Minder AI - The Sales Outreach Tool</b>
          </Box>
          <Box className="get-started-parent">
            <b className="get-started">Get Started</b>
            <Box className="log-in1">{isLogin ? "Log In" : "Register"}</Box>
          </Box>
        </Box>
        {/* <form className="frame-wrapper-login" onSubmit={handleSubmit}> */}
        <form className="frame-wrapper-login" >
          <Box className="frame-group-login">
            {!isLogin && (
              <Box display="flex" gap={8}>
                <TextField
                  className="group-child-login"
                  color="primary"
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  margin="normal"
                  required
                  fullWidth
                  onChange={handleChange}
                  value={formData.firstName}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                />
                <TextField
                  className="group-child-login"
                  color="primary"
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  margin="normal"
                  required
                  fullWidth
                  onChange={handleChange}
                  value={formData.lastName}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                />
              </Box>
            )}
            <Box className="rectangle-parent-login">
              <TextField
                className="group-child-login"
                color="primary"
                variant="outlined"
                label="Email"
                placeholder="Email"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={formData.email}
                autoFocus
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Box>
            <Box className="rectangle-parent-login">
              <TextField
                className="group-child-login"
                color="primary"
                sx={{ width: 488 }}
                variant="outlined"
                label="Password"
                placeholder="Password"
                margin="normal"
                required
                fullWidth
                name="password"
                // type={showPassword ? "text" : "password"}
                type="password"
                id="password"
                onChange={handleChange}
                value={formData?.password}
                autoComplete="current-password"
                error={!!formErrors.password}
                helperText={formErrors.password}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         aria-label="toggle password visibility"
                //         onClick={handleClickShowPassword}
                //         edge="end"
                //       >
                //         {showPassword ? <VisibilityOff /> : <Visibility />}
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            </Box>
            {!isLogin && (
              <Box className="rectangle-parent-login">
                <TextField
                  className="group-child-login"
                  color="primary"
                  variant="outlined"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  margin="normal"
                  required
                  fullWidth
                  
                  onChange={handleChange}
                  value={formData.confirmPassword}
                  error={!!formErrors.confirmPassword}
                  helperText={formErrors.confirmPassword}
                />
              </Box>
            )}
            <Box className="custom-login-btn">
            <Button
              variant="contained"
              className="send-btn"
              onClick={isLogin ? proceedLogin : proceedRegister}
            >
              {isLogin ? "Login" : "Register"}
            </Button>
            <Button
              variant="text"
              className="toggle-btn"
              onClick={() => setIsLogin(!isLogin)}
            >
              {isLogin
                ? "Don't have an account? Register"
                : "Already have an account? Login"}
            </Button>
            </Box>
            <Box className="by-clicking-sign-container">
              <p className="by-clicking-sign-up-you-agree">
                <span>
                  <span>{`By clicking Sign Up, you agree to `}</span>
                  <span className="stafflinepro">{`Compunnel `}</span>
                </span>
                <span>
                  <span className="terms-of-services">Terms of Services</span>
                </span>
              </p>
              <p className="and-privacy-policy">
                <span>
                  <span className="and">{`and `}</span>
                  <span className="privacy-policy">Privacy Policy.</span>
                </span>
              </p>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
function setUsername(value: any) {
  throw new Error("Function not implemented.");
}

function setPassword(value: any) {
  throw new Error("Function not implemented.");
}

