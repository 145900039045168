import React, { useEffect, useState } from "react";
import searchScope from "../Assets/Icons/searchScope.svg"
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Table,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  colors,
  IconButton,
  TextField,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {
  updateNativeData,
  updateSalesforceData,
  updateSelectedRadioValue,
} from "../Services/Actions/actionCreators";
import {
  allContacts,
  contactDetails,
  lists,
  retrieveCampaign,
  retrieveNative,

  updateCampaign,
  salesforceContacts,
  handleHubspotListData,
  retrieveNativecontactDetails,
} from "../Services/Actions/actions";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { PersonData } from "../Common/PersonData";
import "./HubspotData.css";
import { setLoader } from "../Services/Actions/actionCreators";
import Loader from "../Common/Loader/Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SET_SELECTED_VID } from "../Services/Actions/actionTypes";
interface Data {
  Company: number;
  Email: number;
  Name: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort<T>(
  array: readonly T[] | { data: readonly T[] },
  comparator: (a: T, b: T) => number
): T[] {


  // Type guard to check if the input is an object with a `data` property
  const isDataObject = (input: readonly T[] | { data: readonly T[] }): input is { data: readonly T[] } =>
    (input as { data: readonly T[] }).data !== undefined;

  const items = isDataObject(array) ? array.data : array;

  const stabilizedThis = items.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}


const getDataLabel = (value: string): string => {
  switch (value) {
    case "hubspot":
      return "Hubspot Data";
    case "salesforce":
      return "Salesforce Data";
    case "native":
      return "Native Data";
    default:
      return "";
  }
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Full Name",
  },
  {
    id: "Company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isTesting?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ bgcolor: "#E8EEF3" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
  <TableCell
    key={headCell.id}
    align={headCell.numeric ? "right" : "left"}
    padding={headCell.disablePadding ? "none" : "normal"}
  >
    {headCell.label}
  </TableCell>
))}

      </TableRow>
    </TableHead>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function HubspotData(props: { isTesting?: any }) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("Company");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedSalesforceData, setSelectedSalesforceData] = React.useState<
    readonly string[]
  >([]);
  const [selectedNativeListData, setSelectedNativeListData] = React.useState<
    readonly string[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [hubspotListData, setHubspotListData] = React.useState([]);
  const [salesforceListData, setSalesforceListData] = React.useState(
    [] as any[]
  );
  const [nativeListData, setNativeListData] = React.useState(
    [] as any[]
  );
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);

  const [salesforceListDataNeo, setSalesforceListDataNew] = React.useState({
    data: [],
    loading: false,
  });

  const [showCard, setShowCard] = React.useState(false);
  const [selectedRowCount, setSelectedRowCount] = React.useState(0);
  const [list, setList] = useState([]);
  const [salesforceList, setSalesforceList] = useState([]);
  
  const [nativeList, setNativeList] = useState([]);
  const [nativeListDataNeo, setNativeListDataNeo] = React.useState({
    data: [],
    loading: false,
  });
  const [contacts, setContacts] = useState([] as any[]);
  const [loadUserDetail, setLoadUserDetail] = useState(false);
  const [fetchListLoader, setFetchListLoader] = useState(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState("hubspot");
  const salesforceApiData =
    useSelector((state: any) => state.updateSalesForceValue.salesforceData) ||
    [];

    const nativeApiData =
    useSelector((state: any) => state.updateSalesForceValue.nativeDataValue) ||
    [];

    /* istanbul ignore next */
  useEffect(() => {
    fetchList();
    updateCampaign();
    fetchsalesforceList();
    fetchNativeList()
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (selected.length > 0) {
      setLoadUserDetail(false);
      dispatch({ type: SET_SELECTED_VID, payload: selected });
      // contactDetails(selected, dispatch).then((res) => {
      //   setContacts(res?.data);
      //   setLoadUserDetail(false);
      // });
    }
  }, [selected]);
  /* istanbul ignore next */
  useEffect(() => {
    if (props.isTesting) {
      const property: keyof Data = "Name";
      const mockEvent = {} as React.MouseEvent<HTMLElement>;
      handleRequestSort(mockEvent, property);
      getSortedData();
      handleRetrieveCampaign();
      fetchList();
      calculateEmptyRows();
      handleNegativeSelectedIndex();
      handleSelectedIndex();
      getItemCount();
      renderTableContent();
      handleRowClick({});
      handleChangePage("", 1);
      handleRadioValueHubspot();
      handleRadioValueSalesforce();
      handleSelectAllClick({
        target: { value: "12345" },
      } as React.ChangeEvent<HTMLSelectElement>);
      handleClick("", {});
      handleChange({
        target: { value: "12345" },
      } as React.ChangeEvent<HTMLSelectElement>);
      handleChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLSelectElement>);
      handleAnotherValue("test");
      handleChangeRowsPerPage({
        target: { value: "12345" },
      } as React.ChangeEvent<HTMLSelectElement>);
      handleSelectAllClick({
        target: { value: "12345" },
      } as React.ChangeEvent<HTMLSelectElement>);
      renderSalesforceTableContent();
    }
  }, []);
  /* istanbul ignore next */
  const handleRetrieveCampaign = () => {
    retrieveCampaign().then((res) => {
      setSalesforceList(res.data);
    });
  };
  useEffect(() => {
    /* istanbul ignore next */
    if (selected.length > 0) {
      handleRetrieveCampaign();
    }
  }, []);


  const getItemCount = () => {
    if (selectedRadioValue === "hubspot") {
      return hubspotListData?.length;
    } else if (selectedRadioValue === "salesforce") {
      return salesforceListData?.length;
    }  else if (selectedRadioValue === "native") {
      return nativeListData?.length;
    }else {
      return 0;
    }
    
  };

  const count = getItemCount();

  let rowCount = 0;

  if (selectedRadioValue === "hubspot") {
    rowCount = hubspotListData.length;
  } else if (selectedRadioValue === "salesforce") {
    rowCount = salesforceListData.length;
  }else if (selectedRadioValue === "native") {
    rowCount = nativeListData.length;
  } else {
    rowCount = 0;
  }

  let listItems: any;
  if (selectedRadioValue === "hubspot") {
    listItems = !fetchListLoader ? (
      list?.map((name: any) => (
          /* istanbul ignore next */
        <MenuItem key={name.name} value={name.listId} data-testid="xyz">
          {name.name}
        </MenuItem>
      ))
    ) : (
      <Loader />
    );
  } else if(selectedRadioValue == "salesforce") {
      /* istanbul ignore next */
      
    listItems = !fetchListLoader ? (
      salesforceList?.map((item: any) => (
        <MenuItem key={item.itemId} value={item}>
          {item}
        </MenuItem>
      ))
    ) : (
      <Loader />
    );
  }else{
    
      /* istanbul ignore next */
    listItems = !fetchListLoader ? (
      nativeList?.map((item: any) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))
    ) : (
      <Loader />
    );
  }

  const fetchList = async () => {
    setFetchListLoader(true);
    lists()?.then((res) => {
      setList(res?.data);
      setFetchListLoader(false);
    });
  };
  const fetchsalesforceList = async () => {
    setFetchListLoader(true);
    retrieveCampaign().then((res) => {
      setSalesforceList(res?.data);
    });
  };
  const fetchNativeList = async () => {
    setFetchListLoader(true);
    retrieveNative().then((res) => {
     
      setNativeList(res?.data?.campaign_list);
    });
  };
  
  const handleSelectedIndex = (id?: any) => {
    
    if(selectedRadioValue == 'native'){
      setSelectedNativeListData((prevData) =>
        prevData.filter((item: any) => item.data?.id !== id)
      );
      dispatch(
        updateNativeData([...nativeApiData, ...selectedNativeListData])
      );
      
    }else{
      setSelectedSalesforceData((prevData) =>
        prevData.filter((item: any) => item?.data?.id !== id)
      );
      dispatch(
        updateSalesforceData([...salesforceApiData, ...selectedSalesforceData])
      );
    }
   
  };

  const handleNegativeSelectedIndex = (row?: any) => {
    const selectedData = row;
    if(selectedRadioValue == 'native'){
     setSelectedNativeListData((prevData) => [...prevData, selectedData])
     dispatch(
      updateNativeData([...nativeApiData, ...selectedNativeListData])
    );
    }else{
      setSelectedSalesforceData((prevData) => [...prevData, selectedData]);
      dispatch(
        updateSalesforceData([...salesforceApiData, ...selectedSalesforceData])
      );
    }
   
  };
    /* istanbul ignore next */
    const handleRowClick = async (row: any) => {
     
      /* istanbul ignore next */
      let id :any;
    
      // Determine the ID based on the selectedRadioValue
      if (selectedRadioValue === "hubspot") {
        id = row.VID;
      } else if (selectedRadioValue === "salesforce") {
        id = row?.data?.id;
      } else if (selectedRadioValue === "native") {
        id = row?.data?.id;
      }
    
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
    
      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((item) => item !== id);
      }
    
      setSelected(newSelected);
      setSelectedRowCount(newSelected?.length);
      setShowCard(newSelected?.length > 0);
    
      if (selectedRadioValue === "salesforce" ||selectedRadioValue === "native") {
        // Update selectedSalesforceData
        if (selectedIndex === -1) {
          handleNegativeSelectedIndex(row);
        } else {
          handleSelectedIndex(id);
        }
      }
    };
    
  const handleAnotherValue = async (value?: any) => {
    setSalesforceListDataNew((v) => ({ ...v, loading: true }));
    await salesforceContacts(value).then((res) => {
      
      setSalesforceListData(res?.data);
      dispatch(setLoader(false));
      dispatch(handleHubspotListData(res?.data));
      setSalesforceListDataNew((v) => ({
        ...v,
        data: res?.data,
        loading: false,
      }));
    });
  };
  const handleNativeList = async (value?: any) => {
    setNativeListDataNeo((v) => ({ ...v, loading: true }));
    await retrieveNativecontactDetails(value).then((res) => {
   
      setNativeListData(res?.data?.data);
      dispatch(setLoader(false));
      dispatch(handleHubspotListData(res?.data?.data));
      setNativeListDataNeo((v) => ({
        ...v,
        data: res?.data?.data,
        loading: false,
      }));
    });
  };
  /* istanbul ignore next */
  const handleChange = async (event: any) => {
   
    dispatch(setLoader(true));
    setSelected([]);
    
    setSelectedSalesforceData([]);
    setSelectedNativeListData([])
    setShowCard(false);
    setSelectedRowCount(0);
    const {
      target: { value },
    } = event;
    if (value === "") {
      dispatch(setLoader(false));
      return;
    }
    if (selectedRadioValue === "hubspot") {
      await allContacts(value).then((res) => {
        setHubspotListData(res?.data);
        dispatch(setLoader(false));
        setSalesforceListDataNew((v) => ({
          ...v,
          data: res?.data,
          loading: false,
        }));
      });
    } else if(selectedRadioValue == "salesforce") {
      handleAnotherValue(value);
    }else{
      handleNativeList(value);
    }

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
      /* istanbul ignore next */
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRadioValueHubspot = () => {
    const newSelected = hubspotListData.map((n: any) => n?.VID);
    setSelected(newSelected);
    setShowCard(newSelected?.length > 0);
    setSelectedRowCount(newSelected?.length);
  };
  const handleRadioValueSalesforce = () => {
    const newSelected = salesforceListData.map((n: any) => n.data.id);
    setSelectedSalesforceData(salesforceListData);
    setSelected(newSelected);
    setShowCard(newSelected?.length > 0);
    setSelectedRowCount(newSelected?.length);
  };
  const handleRadioValueNative = () => {
   
    const newSelected = nativeListData.map((n: any) => n.data.id);
    // const newSelected = nativeListData.map((n: any) => n.data.id);
    setSelectedNativeListData(nativeListData);
    setSelected(newSelected);
    setShowCard(newSelected?.length > 0);
    setSelectedRowCount(newSelected?.length);
  };
    /* istanbul ignore next */
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked && selectedRadioValue === "hubspot") {
      handleRadioValueHubspot();
      return;
    } else if (event.target.checked && selectedRadioValue === "salesforce") {
      handleRadioValueSalesforce();

      return;
    } else if(event.target.checked && selectedRadioValue === "native") {
      handleRadioValueNative();
    }else{
      // Clear the selection
      setSelected([]);
      setSelectedSalesforceData([]);
      setSelectedNativeListData([])
      setShowCard(false);
      setSelectedRowCount(0);
    }
    
  };
/* istanbul ignore next */
  const handleClick = (event: any, row: any) => {
    
    let id:any;
    /* istanbul ignore next */
    if (selectedRadioValue === "hubspot") {
      id = row.VID;
    } else if (selectedRadioValue === "salesforce") {
      id = row?.data?.id;
    } else if (selectedRadioValue === "native") {
      id = row.data.id;
    }
   
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) =>{
  
    return selected.indexOf(id) !== -1;
  } 

  // Avoid a layout jump when reaching the last page with empty hubspotListData.
  const calculateEmptyRows = () => {
    let itemCount = 0;

    if (selectedRadioValue === "hubspot") {
      itemCount = hubspotListData.length;
    } else if (selectedRadioValue === "salesforce") {
      itemCount = salesforceListData.length;
    }else if (selectedRadioValue === "native") {
      itemCount = nativeListData.length;
    }
    return Math.max(0, (1 + page) * rowsPerPage - itemCount);
  };

  const emptyRows = page > 0 ? calculateEmptyRows() : 0;

  const getSortedData = () => {
    if (selectedRadioValue === "hubspot") {
      return hubspotListData;
    } else if (selectedRadioValue === "salesforce") {
      return salesforceListData;
    } else if(selectedRadioValue === "native"){
      return nativeListData;
    } else{
      return [];
    }
  };

  const visibleRows = React.useMemo(() => {
    /* istanbul ignore next */
    const sortedData = getSortedData();
    return stableSort(sortedData, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [
    selectedRadioValue,
    order,
    orderBy,
    page,
    rowsPerPage,
    hubspotListData,
    salesforceListData,
    nativeListData
  ]);
/* istanbul ignore next */
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* istanbul ignore next */
    setSelectedRadioValue(event.target.value);
    const newValue = event.target.value;
    dispatch(updateSelectedRadioValue(newValue));
    setSelected([]);
    setSelectedSalesforceData([]);
    setSelectedNativeListData([])
    setShowCard(false);
    setSelectedRowCount(0);
  };

  const renderSalesforceTableContent = () => {
  
    /* istanbul ignore next */
    if (
      selectedRadioValue === "salesforce" &&
      salesforceListDataNeo?.data?.length > 0
    ) {
      
      return (
        /* istanbul ignore next */
        <Box>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "2% 0",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-search"
                size="small"
                label="Search contacts"
                type="search"
               
                className="search-text-field"
              />
              <IconButton
                className="search-icon"
                data-testid="search-btn"
               
              >
                <img className="custom-img" src={searchScope} />
              </IconButton>
            </Box>
          <TableContainer className="custom-overflow">
          
            <Table
              className="custom-td-height"
              sx={{
                border: 1,
                borderColor: "#DBDBDB",
                maxHeight: "270px",
                overflow: "auto",
              }}
              aria-labelledby="tableTitle"
              data-testid="table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
              />
              <TableBody>
                {visibleRows?.map((row: any, index) =>/* istanbul ignore next */ {
                  
                  const isItemSelected = isSelected(row?.data?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover={true}
                      role="checkbox"
                      onClick={(event) => {
                        handleRowClick(row);
                        handleClick(event, row);
                      }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#0257AB",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row">
                        {row?.data?.properties?.firstname +
                          " " +
                          row?.data?.properties?.lastname}
                      </TableCell>
                      <TableCell align="left">
                        {row?.data?.properties?.company}
                      </TableCell>
                      <TableCell align="left">
                        {row?.data?.properties?.email}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="pagi-tehandleChangePagext"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton showLastButton
          />
        </Box>
      );
    }else if(selectedRadioValue === "native" &&
      nativeListDataNeo?.data?.length > 0)
      {
        /* istanbul ignore next */
        return (
          <Box>
<Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "2% 0",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-search"
                size="small"
                label="Search contacts"
                type="search"
               
                className="search-text-field"
              />
              <IconButton
                className="search-icon"
                data-testid="search-btn"
               
              >
                <img className="custom-img" src={searchScope} />
              </IconButton>
            </Box>

          <TableContainer className="custom-overflow">
            <Table
              className="custom-td-height"
              sx={{
                border: 1,
                borderColor: "#DBDBDB",
                maxHeight: "270px",
                overflow: "auto",
              }}
              aria-labelledby="tableTitle"
              data-testid="table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
              />
              <TableBody>
                {visibleRows.map((row: any, index) =>/* istanbul ignore next */ {
                  
                
                  const isItemSelected = isSelected(row?.data?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover={true}
                      role="checkbox"
                      onClick={(event) => {
                        handleRowClick(row);
                        handleClick(event, row);
                      }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#0257AB",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row">
                        {row?.data?.properties?.firstname +
                          " " +
                          row?.data?.properties?.lastname}
                      </TableCell>
                      <TableCell align="left">
                        {row?.data?.properties?.company}
                      </TableCell>
                      <TableCell align="left">
                        {row?.data?.properties?.email}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="pagi-tehandleChangePagext"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        );
      }

    return null;
  };
/* istanbul ignore next */
  const renderTableContent = () => {
  
   
      return (
        <>
          {selectedRadioValue === "hubspot" &&
          salesforceListDataNeo?.data?.length > 0 ? (
            <Box>
              <TableContainer className="custom-overflow">
                <Table
                  className="custom-td-height"
                  sx={{
                    border: 1,
                    borderColor: "#DBDBDB",
                    maxHeight: "270px",
                    overflow: "auto",
                  }}
                  aria-labelledby="tableTitle"
                  data-testid="table"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rowCount}
                  />
                  <TableBody>
                    {visibleRows.map((row: any, index) => {
                      const isItemSelected = isSelected(row?.VID);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover={true}
                          role="checkbox"
                          onClick={(event) => {
                            handleRowClick(row);
                            handleClick(event, row);
                          }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{
                            cursor: "pointer",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#0257AB",
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell component="td" id={labelId} scope="row">
                            {row.Name}
                          </TableCell>
                          <TableCell align="left">{row.Company}</TableCell>
                          <TableCell align="left">{row.Name}</TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="pagi-text"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          ) : (
            renderSalesforceTableContent()
          )}
        </>
      );
    
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="table-box-header">
        <Grid container={true} spacing={0} columns={12} className="table-box">
          <Grid item={true} xs={8} className="custom-table-box1">
            <Box className="tbl-grid-box">
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="radio-text"
                    >
                      Select contact source
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="hubspot"
                      value={selectedRadioValue}
                      onChange={handleRadioChange}
                      className="radio-text"
                    >
                      <FormControlLabel
                        value="hubspot"
                        control={<Radio />}
                        label="Hubspot"
                      />
                      <FormControlLabel
                        data-testid="salesforce-radio-button"
                        value="salesforce"
                        control={<Radio />}
                        label="Salesforce"
                      />
                      <FormControlLabel
                        data-testid="salesforce-radio-button"
                        value="native"
                        control={<Radio />}
                        label="Native"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Typography
                  variant="h5"
                  fontWeight={700}
                  mt={"24px"}
                  mb={"16px"}
                >
                  {}
                </Typography>
                <Typography
                  variant="h6"
                  noWrap={false}
                  fontWeight={600}
                  mt={"16px"}
                >
                  Select a list to display contacts
                </Typography>
                <FormControl className="custom-select-set">
                  <Select
                    displayEmpty={true}
                    data-testid="select-ddn"
                    inputProps={{ "aria-label": "Without label" }}
                    value={personName}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value="" data-testid="input-label">
                      Select a list
                    </MenuItem>
                    {listItems}
                  </Select>
                </FormControl>
              </Box>
              {/* Boolean loader check... */}
              {renderTableContent()}
              {salesforceListDataNeo?.loading === true || nativeListDataNeo?.loading ===true ? <Loader /> : ""}
            </Box>
          </Grid>
          <Grid item={true} xs={4} className="custom-table-box-2">
          <Box className="custom-text-lft">
  {showCard ? (
        selectedRadioValue === "native" ? (
          <PersonData
            selectedRowCount={selectedRowCount}
            contacts={contacts}
            loadUserDetail={loadUserDetail}
            selectedRadioValue={selectedRadioValue}
            selectedSalesforceData={selectedNativeListData}
          />
        ) : (
          <PersonData
            selectedRowCount={selectedRowCount}
            contacts={contacts}
            loadUserDetail={loadUserDetail}
            selectedRadioValue={selectedRadioValue}
            selectedSalesforceData={selectedSalesforceData}
          />
        )
  ) : (
    <Box className="main-div">
      <Box className="box-hdr-txt">
        {/* <Typography
          variant="h5"
          fontWeight={700}
          lineHeight={"26px"}
          ml={"32px"}
          mb={"8px"}
        >
          Summary
        </Typography> */}
      </Box>
    </Box>
  )}
</Box>

          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
