import { UPDATE_SALESFORCE_DATA , UPDATE_NATIVE_DATA} from "../Actions/actionTypes";

const initialState = {
  salesforceData: [],
};

const updateSalesForceValue = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_SALESFORCE_DATA:
      return { ...state, salesforceData: action.payload };
      case UPDATE_NATIVE_DATA:
        return { ...state, nativeDataValue: action.payload };
    default:
      return state;
  }
};


export default updateSalesForceValue;
