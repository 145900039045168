// Action Types
export const GET_EMAIL_DETAIL_START: string = "GET_EMAIL_DETAIL_START";
export const GET_EMAIL_DETAIL_SUCCESS: string = "GET_EMAIL_DETAIL_SUCCESS";
export const GET_EMAIL_DETAIL_FAILURE: string = "GET_EMAIL_DETAIL_FAILURE";

export const SET_HEADER_STEP_ONE: string = "SET_HEADER_STEP_ONE";
export const SET_HEADER_STEP_TWO: string = "SET_HEADER_STEP_TWO";
export const SET_HEADER_STEP_THREE: string = "SET_HEADER_STEP_THREE";
export const SET_LOADING: string = "SET_LOADING";
export const SET_SELECTED_VID: string = "SET_SELECTED_VID";
export const UPDATE_SELECTED_RADIO_VALUE: string =
  "UPDATE_SELECTED_RADIO_VALUE";

export const UPDATE_SALESFORCE_DATA: string = "UPDATE_SALESFORCE_DATA";
export const UPDATE_NATIVE_DATA: string = "UPDATE_NATIVE_DATA";
export const SET_GET_PROFILE_DATA: string = "SET_GET_PROFILE_DATA";
